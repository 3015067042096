import { useMemo } from "react";
import { useInstanceRoute } from "@tecnobit-srl/route-types-instance/lazy";
const useCoreRouteTypes = () => {
  const instanceRoute = useInstanceRoute();
  return useMemo(
    () => ({
      ...instanceRoute
    }),
    [instanceRoute]
  );
};
export {
  useCoreRouteTypes
};
